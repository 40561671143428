<template>
    <div class="add-driver">
        <b-button v-b-modal.add-driver-modal>Add Company</b-button>

        <b-modal id="add-driver-modal" title="Add Company" hide-footer>
            <FormulateForm @submit="addNewCompany" class="add-driver-form" v-model="formValues">
                <div class="row">
                    <div class="col-sm-12">
                        <FormulateInput name="name" type="text" label="Company Name" placeholder="Company Name"
                            validation="required" />
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-3">
                        <FormulateInput name="country_code" type="number" label="Country code"
                            placeholder="Country code"  />
                    </div>
                    <div class="col-9">
                        <FormulateInput name="phone_number" type="number" label="Phone number"
                            placeholder="Phone number"  />
                    </div>
                </div>


                <div class="row">
                   <div class="col-sm-12">
                        <FormulateInput name="email" type="email" label="Email address" placeholder="Email address"
                            validation="optional|email" />
                    </div>
                </div>
              

                <div class="float-right">
                    <FormulateInput type="submit" :label="submitting ? 'Registering...' : 'Register'" />
                </div>
            </FormulateForm>
        </b-modal>
    </div>
</template>

<script>

import { vehicle_company } from "@/config/api/vehicle_company";

export default {
    data() {
        return {
            formValues: {},
            submitting: false,
        }
    },
    methods: {
        addNewCompany(data) {
            const api = vehicle_company.add
            api.data = data
            this.submitting = true
            this.generateAPI(api)
                .then(() => {
                    // this.$formulate.reset("updateModelForm");
                    this.$bvToast.toast("Driver added successfully!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                    this.$bvModal.hide('add-driver-modal')
                })
                .catch((err) => {
                    this.$bvToast.toast(`Driver is not added, ${err.response.data.error}!`, {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
     
                })
                .finally(() => {
                    this.submitting= false;
                });

        }
    }
}
</script>

<style >
.add-driver {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>

