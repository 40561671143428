<template>
  <FormulateForm
    @submit="addNewCompany"
    class="add-driver-form"
    v-model="formValues"
  >
    <div class="row">
      <div class="col-sm-12">
        <FormulateInput
          name="name"
          type="text"
          label="Company Name"
          v-model="companyData.name"
          placeholder="Company Name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <FormulateInput
          name="country_code"
          type="number"
          label="Country code"
          v-model="companyData.country_code"
          placeholder="Country code"
        />
      </div>
      <div class="col-9">
        <FormulateInput
          v-model="companyData.phone_number"
          name="phone_number"
          type="number"
          label="Phone number"
          placeholder="Phone number"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <FormulateInput
          name="email"
          v-model="companyData.email"
          type="email"
          label="Email address"
          placeholder="Email address"
          validation="optional|email"
        />
      </div>
    </div>

    <div class="float-right">
      <FormulateInput
        type="submit"
        :label="submitting ? 'Registering...' : 'Register'"
      />
    </div>
  </FormulateForm>
</template>

<script>
import { vehicle_company } from "@/config/api/vehicle_company";

export default {
  props: ["company"],
  data() {
    return {
      formValues: {},
      companyData: {},
      submitting: false,
    };
  },
  mounted() {
    this.companyData = { ...this.company };
    this.emailAddress = this.company.users_list[0].email;
    console.log(this.company);
  },
  methods: {
    addNewCompany(data) {
      const companyId = this.company._id;
      data.company_id = companyId;
      const api = vehicle_company.update;
      api.data = data;
      this.submitting = true;
      this.generateAPI(api)
        .then(() => {
          // this.$formulate.reset("updateModelForm");
          this.$bvToast.toast("Driver updated successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("callback");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Driver is not updated, ${err.response.data.message}!`,
            {
              title: "Create",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style>
.add-driver {
  margin: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
